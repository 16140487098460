import React, { useState, useEffect } from "react"
import * as notes from "../../notes"
import {
  NoteTextStyle,
  NoteCardStyle,
  TuningFork,
  TuningForkCard,
  TuningForkSVGPath,
  Text,
} from "../../styles/NotePlayer"

const useAudio = (note: string) => {
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined)
  const [playing, setPlaying] = useState(false)
  const play = () => setPlaying(true)
  const pause = () => setPlaying(false)

  useEffect(() => {
    setAudio(new Audio(note))
  }, [note])

  useEffect(() => {
    if (audio !== undefined) {
      playing ? audio.play() : audio.pause()
      audio.loop = true
    }
  }, [playing])
  return { playing, play, pause }
}

const NotePlayer = (props) => {
  const startnote = props.note
    .toLowerCase()
    .replace(/[0-9]/, "")
    .replace("#", "i")
  const { playing, play, pause } = useAudio(notes[startnote])
  const NoteButton =
    props.text === "" ? (
      <NoteCardStyle
        onMouseDown={play}
        onMouseUp={pause}
        onTouchStart={play}
        onTouchEnd={pause}
        className={props.className}
      >
        <TuningForkCard viewBox={"0 0 15 100"}>
          <TuningForkSVGPath />
        </TuningForkCard>
      </NoteCardStyle>
    ) : (
      <NoteTextStyle
        onMouseDown={play}
        onMouseUp={pause}
        onTouchStart={play}
        onTouchEnd={pause}
        className={props.className}
      >
        <TuningFork viewBox={"0 0 15 100"}>
          <TuningForkSVGPath />
        </TuningFork>
        <Text>{props.text}</Text>
      </NoteTextStyle>
    )
  return NoteButton
}

export default NotePlayer
