import React from "react"
import styled from "styled-components"
import { SongTag } from "../../types/song"
import { globalTransition } from "../../styles/Style"

interface SongTagsProps {
  tag: SongTag[]
  shorten?: boolean
}

const Badge = styled.div<{ badgeColor: string | undefined }>`
  border-radius: 2em;
  color: var(--bg);
  background-color: ${(props) =>
    props.badgeColor ? props.badgeColor : "var(--fg)"};
  display: inline-block;
  transition: all ${globalTransition};
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  margin: 0.16666666666667em 0.2em;
  text-align: center;
  white-space: nowrap;
`

const Wrapper = styled.div`
  margin: auto 0;
`

export const SongTags = (props: SongTagsProps) => {
  if (props.tag) {
    const songTags = props.tag.filter((tag: SongTag) => tag.value)

    const badges = songTags.map((tag: SongTag) => {
      const tagContent: string = props.shorten ? tag.shortLabel : tag.label
      return (
        <Badge key={`${tag.tag}-${tag.value}`} badgeColor={tag.color}>
          <b>{tagContent}</b>
        </Badge>
      )
    })

    return <Wrapper>{badges}</Wrapper>
  } else {
    return null
  }
}
