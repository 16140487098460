import React from "react"
import SEO from "../components/Layout/SEO"
import SearchResults from "../components/Search/SearchResults"

const IndexPage: React.FunctionComponent = () => {
  return (
    <>
      <SEO keywords={[`snapsen`, `snapsvisa`, `snapsvisor`, `sångbok`]} />
      <SearchResults />
    </>
  )
}

export default IndexPage
