import React, { memo } from "react"
import { Link } from "gatsby"
import { Song } from "../../types/song"
import styled, { keyframes, css } from "styled-components"
import NotePlayer from "../Media/NotePlayer"
import { ThemedSVG } from "../Media/ThemedSVG"
import { SongTags } from "./SongTags"
import { SnapsenTagLabelMap } from "../../types/snapsenConfig"
import slugify from "underscore.string/slugify"
import { useAsyncEffect } from "@react-hook/async"
import defaultIcon from "../../images/cat_icons/mug.svg"

const fadeIn = keyframes`
  from {
    transform: rotateX(90deg);
  }

  to {
    transform: rotateX(0deg);
  }
`

const Card = styled.div<{ animate: boolean }>`
  display: flex;
  height: 114px;
  margin: 8px;
  box-shadow: 2px 2px 6px var(--neutral10);
  border-radius: 5px;
  font-weight: 300;
  overflow: hidden;
  ${(props) =>
    props.animate
      ? css`
          animation: ${fadeIn} 0.15s cubic-bezier(0.76, 0, 0.63, 1);
        `
      : ``}
`

const CardLink = styled(Link)`
  display: flex;
  flex: 1;
  min-width: 0;
`

const CardLinkPlaceholder = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
`

const ImgBox = styled(ThemedSVG)<{ loading: boolean; type: string }>`
  background-color: ${({ loading }) => (loading ? "var(--primary)" : null)};
  height: auto;
  width: 50px;
  min-width: 0;
  flex: none;
  overflow: hidden;
  --svg-color: ${({ type }) => `var(--${type})`};
`

const Text = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 0;
  color: var(--fg);
  padding: 0 1rem;
`

const Player = styled(NotePlayer)`
  width: min(50px, 15%);
  min-width: 0;
  flex: none;
`

const CardTitle = styled.h4`
  flex: none;
  margin: 0;
  padding: 0.7rem 0 0.3rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Excerpt = styled.i`
  flex: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface SongProps {
  song: Song
  animate?: boolean
  defaultType: SnapsenTagLabelMap
  tagMeta?: SnapsenTagLabelMap[]
  useCategoryIcons?: boolean
}

const SongListItem = memo<SongProps>(
  ({
    song: { id, title, slug, contentFull, tag, book, frontmatter },
    animate = false,
    useCategoryIcons = true,
    defaultType,
    tagMeta,
  }) => {
    const typeMeta = tagMeta
      ? tagMeta.find((tag) => tag.value === frontmatter.type)
      : defaultType

    const type = typeMeta ? slugify(typeMeta.value) : defaultType.value
    const icon = typeMeta ? typeMeta.icon : defaultType.icon

    const { value, error } = useAsyncEffect(
      () =>
        import(/* webpackMode: "eager" */ `../../images/cat_icons/${icon}.svg`),
      [icon]
    )
    const loadingIndicator = id === "noid"

    const CardLinkContent = () => (
      <>
        {useCategoryIcons && (
          <ImgBox
            src={value && value.default ? value.default : defaultIcon}
            loading={loadingIndicator}
            type={type}
          />
        )}
        <Text>
          <CardTitle>{title}</CardTitle>
          <Excerpt>{contentFull}</Excerpt>
          {tag && <SongTags tag={tag} shorten />}
        </Text>
      </>
    )

    return (
      <Card animate={animate} className="noselect">
        {!loadingIndicator ? (
          <CardLink to={`/${book.slug}-${slug}`}>
            <CardLinkContent />
          </CardLink>
        ) : (
          <CardLinkPlaceholder>
            <CardLinkContent />
          </CardLinkPlaceholder>
        )}
        {frontmatter?.startnote && (
          <Player note={frontmatter.startnote[0]} text={""} />
        )}
      </Card>
    )
  }
)

export default SongListItem
