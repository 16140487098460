import React from "react"
import { Song } from "../../types/song"
import SongListItem from "./SongListItem"
import { SongTag } from "../../types/song"
// @ts-ignore
import { getColumnProps } from "react-flexbox-grid"
import styled from "styled-components"
import { VirtuosoGrid, ListRange } from "react-virtuoso"
import { graphql, useStaticQuery } from "gatsby"
import type { SnapsenMeta } from "../../types/snapsenConfig"

interface SongListProps {
  songs: Song[]
  loadMore: (range: ListRange) => void
  hasMore: boolean
}

const ListWrapper = styled.div`
  margin: 0 -8px;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface ColContainerProps {
  children?: any
}

const ColContainer = (props: ColContainerProps) => {
  const colSizes = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4,
  }
  const colProps = getColumnProps(colSizes)
  return <div className={colProps.className}>{props.children}</div>
}

const loadSong = {
  id: "noid",
  title: "Laddar...",
  slug: "loadSlug",
  contentFull: "Laddar mera sånger...",
  book: {
    title: "",
    slug: "loadSlug",
  },
  frontmatter: {
    type: "notype",
  },
}

const SongList = React.memo<SongListProps>(
  ({
    // Array of items loaded so far.
    songs,
    loadMore,
    hasMore,
  }) => {
    const { siteQuery } = useStaticQuery(
      graphql`
        query {
          siteQuery: site {
            siteMetadata {
              tags {
                labelMap {
                  label
                  shortLabel
                  value
                  icon
                  color
                  colorFilter
                  darkColorFilter
                }
                value
              }
              defaultType {
                label
                shortLabel
                value
                icon
                color
                colorFilter
                darkColorFilter
              }
              useCategoryIcons
            }
          }
        }
      `
    )

    const snapsenTags = siteQuery.siteMetadata.tags.filter(
      (tag: SongTag) => tag.value === "type"
    )[0].labelMap
    const { useCategoryIcons, defaultType } =
      siteQuery.siteMetadata as SnapsenMeta

    return (
      <ListWrapper>
        <VirtuosoGrid
          useWindowScroll
          rangeChanged={loadMore}
          totalCount={hasMore ? songs.length + 1 : songs.length}
          overscan={20}
          components={{
            Item: ColContainer,
            List: ListContainer,
          }}
          itemContent={(index) => {
            if (index < songs.length) {
              return (
                <SongListItem
                  song={songs[index]}
                  animate={true}
                  tagMeta={snapsenTags}
                  defaultType={defaultType}
                  useCategoryIcons={useCategoryIcons}
                />
              )
            } else {
              return <SongListItem song={loadSong} defaultType={defaultType} />
            }
          }}
        />
      </ListWrapper>
    )
  }
)

export default SongList
