import styled from "styled-components"
import { globalTransition } from "./Style"

export const Button = styled.button`
  background-color: var(--primary);
  transition: all ${globalTransition};
  color: var(--fg);
  border: 0;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
  text-align: center;
`
