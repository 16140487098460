import React from "react"
import styled from "styled-components"
import { globalTransition } from "./Style"
import { Button } from "./Button"

export const TuningForkSVGPath = () => (
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M14.4,0C13.1,0,11,1.6,11,3.6V59c0,3-0.6,5.4-2.6,5.4S5.8,62,5.8,59V3.6c0-2-2.1-3.6-3.4-3.6    S-1,1.6-1,3.6V59c0,5.7,2.5,10.5,6,12.1v24c0,2,2.1,3.6,3.4,3.6s3.4-1.6,3.4-3.6v-24c3.5-1.6,6-6.4,6-12.1V3.6    C17.8,1.6,15.7,0,14.4,0"
  />
)

export const NoteTextStyle = styled(Button)`
  @media (max-width: 470px) {
    font-size: 1rem;
  }
  @media (min-width: 471px) {
    font-size: 1.5rem;
  }
`
export const NoteCardStyle = styled.button`
  background-color: var(--primary);
  color: var(--fg);
  border: 0;
  width: 6em;
`
export const TuningFork = styled.svg`
  fill: var(--bg);
  transition: all ${globalTransition};
  margin-bottom: 0;
  text-align: center;
  width: 0.5em;
  height: 2em;
`
export const TuningForkCard = styled.svg`
  fill: var(--bg);
  transition: all ${globalTransition};
  margin-bottom: 0;
  text-align: center;
  width: 1.5em;
  height: 6em;
`
export const Text = styled.div`
  padding: 0.5em 1em 0;
  float: right;
`
