import { useState, useEffect, useCallback } from "react"
import { Document, Id } from "flexsearch"
import { Song, SongTag } from "../types/song"
import { graphql, useStaticQuery } from "gatsby"
import { useSearch } from "../components/Search/SearchContext"
import type { ListRange } from "react-virtuoso"

interface IndexSong {
  id: string
  title: string
  contentFull: string
  melody?: string
  melodyCredit?: string
  lyricsCredit?: string
  tag: string[]
}

interface Offset {
  field: string
  offset: number
}

/**
 * Search for songs using Flexsearch
 * @param {string} query - Search string.
 * @param {SongTag[]} tags - List of tags the search results will be filtered with
 * @param {number} offset - Offset search results by this much
 * @param {number} limit - The maximum amount of search results to return
 * @param {Document<IndexSong>} index - Flexsearch index containing songs to search
 * @param store - Map containing songs, with IDs as keys
 * @return {Song[]} An array containing song search results
 *
 */
const flexSearch = (
  query: string,
  tags: readonly SongTag[],
  limit: number,
  index: Document<IndexSong>,
  store: { [k: Id]: Song },
  offsets: Offset[]
): [Song[], Offset[]] => {
  if (!index || !store) {
    console.warn("No search index or store available")
    return [[], []]
  }

  let songs: Song[], counts: Offset[]
  if (query) {
    const options = offsets.map(({ field, offset }) => ({
      field,
      limit,
      offset,
    }))

    const rawResults = index!.search(query, options)

    const flatResults = rawResults.flatMap((res) => res.result)

    // Map song IDs to song data in the search store.
    songs = flatResults.map((id) => store![id])

    counts = rawResults
      .filter((res) => res.field)
      .map((res) => ({ field: res.field, offset: res.result.length }))
  } else {
    // Use store as defaultSongs is shuffled
    // Is not incremental
    songs = Object.values(store)
    counts = []
  }

  if (tags.length > 0) {
    songs = songs.filter((s) => hasTags(s, tags))
  }

  return [songs, counts]
}

const hasTags = (song: Song, tags: readonly SongTag[]): boolean => {
  for (const searchTag of tags) {
    let found = false
    for (const songTag of song.tag) {
      if (searchTag.tag === songTag.tag && searchTag.value === songTag.value) {
        found = true
      }
    }
    if (!found) {
      return false
    }
  }
  return true
}

const filterSongs = (songs: Song[]) => [...new Set(songs)]

const zeroOffsets = (fields: string[]): Offset[] =>
  fields.map((field) => ({ field, offset: 0 }))

const incOffsetsAndHasMore = (
  offsets: Offset[],
  counts: Offset[],
  searchLimit: number
): [Offset[], boolean] => {
  let newHasMore = false
  const newOffsets = offsets.map(({ field, offset }) => {
    const count = counts.find((c) => c.field === field)
    const newOffset = count ? offset + count.offset : offset
    if (newOffset - offset === searchLimit) {
      newHasMore = true
    }
    return { field, offset: newOffset }
  })
  return [newOffsets, newHasMore]
}

interface UseLoadingSearchReturn {
  songs: Song[]
  hasMore: boolean
  loadMore: (range: ListRange) => void
}

export const useLoadingSearch = (): UseLoadingSearchReturn => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            songListSearchLimit
            songListLoadThreshold
          }
        }
      }
    `
  )
  const [songs, setSongs] = useState<Song[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [offsets, setOffsets] = useState([])

  const { index, store, query, tags, defaultSongs, fields, searching } =
    useSearch()
  const searchLimit = site.siteMetadata.songListSearchLimit
  const loadThreshold = site.siteMetadata.songListLoadThreshold

  const loadMore = useCallback(
    ({ endIndex }) => {
      if (!searching) {
        return
      }
      if (hasMore && songs.length <= endIndex + 1 + loadThreshold) {
        const [rawSongs, counts] = flexSearch(
          query,
          tags,
          searchLimit,
          index,
          store,
          offsets
        )
        const newSongs = filterSongs(songs.concat(rawSongs))
        const [newOffsets, newHasMore] = incOffsetsAndHasMore(
          offsets,
          counts,
          searchLimit
        )

        setSongs(newSongs)
        setHasMore(newHasMore)
        setOffsets(newOffsets)
      }
    },
    [query, tags, index, store, fields, searching, songs, hasMore]
  )

  // Reset search
  useEffect(() => {
    if (searching) {
      const offsets = zeroOffsets(fields)
      const [rawSongs, counts] = flexSearch(
        query,
        tags,
        searchLimit,
        index,
        store,
        offsets
      )
      const newSongs = filterSongs(rawSongs)
      const [newOffsets, newHasMore] = incOffsetsAndHasMore(
        offsets,
        counts,
        searchLimit
      )

      setSongs(newSongs)
      setHasMore(newHasMore)
      setOffsets(newOffsets)
    } else {
      setSongs(defaultSongs)
      setHasMore(false)
      setOffsets([])
    }
  }, [query, tags, index, store, fields, searching])

  return { songs, hasMore, loadMore }
}
