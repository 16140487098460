import React, { FunctionComponent } from "react"
import SongList from "../Song/SongList"
import styled from "styled-components"
import { useSearch } from "./SearchContext"
import { useLoadingSearch } from "../../utils/useLoadingSearch"

const SearchHintStyle = styled.div`
  margin-top: 0;
`

const strTitle = "Sök"
const strSearchHint = "Sök efter titel, sidnummer, text eller sångtyp."
const strSearchFailed = "Inga sånger hittades."

/**
 * If search is active, displays search results.
 * Otherwise renders child components.
 */
const SearchResults: FunctionComponent = () => {
  const { searching, focused } = useSearch()
  const { songs, loadMore, hasMore } = useLoadingSearch()

  if (focused && !searching) {
    return (
      <SearchHintStyle>
        <h3>{strTitle}</h3>
        {strSearchHint}
      </SearchHintStyle>
    )
  } else if (searching && songs.length === 0) {
    return (
      <SearchHintStyle>
        <h3>{strTitle}</h3>
        {strSearchFailed}
      </SearchHintStyle>
    )
  } else {
    return <SongList songs={songs} loadMore={loadMore} hasMore={hasMore} />
  }
}

export default SearchResults
